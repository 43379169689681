.modal-edit-report-header {
  .free-text-input {
    border-bottom: 1px solid var(--ion-color-medium);
    height: 50px;
    margin-bottom: 16px;
    span {
      display: block;
      font-size: 14px;
      padding: 0 16px;
    }
    ion-input {
      --padding-start: 16px;
      --padding-top: 5px;
    }
    .error-editing {
      display: block;
      margin-bottom: 12px;
      font-size: 12px;
      color: var(--ion-color-danger);
    }
  }
}
