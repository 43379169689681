.report-header {
  text-align: left;
  .patient-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      display: flex;
    }
  }
  h1 {
    font-size: 36px;
  }
  .report-header-content {
    display: grid;
    gap: 5%;
    grid-template-columns: 55% 40%;
    .subcontent {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      span {
        margin-right: 6px;
      }
    }
  }
  .label {
    display: block;
    text-transform: uppercase;
    font-size: 13px;
  }
  .report-header-card {
    border: 1px solid var(--ion-color-light-shade);
    border-radius: 10px;
    padding: 10px 16px;
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
      align-items: flex-end;
    }
  }
  .report-header-extra-data {
    .content-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      padding: 10px 0;
    }
    .subcontent,
    .label {
      color: var(--ion-color-medium);
    }
  }
}
