.therapies-trials {
  border-radius: 10px;
  background-color: var(--ion-color-light);
  padding: 16px;
  text-align: left;
  margin: 20px 0;
  .table-title {
    margin-bottom: 10px;
  }
  .tt-title {
    display: flex;
    justify-content: space-between;
  }
  .treatments-title {
    margin: 16px 0;
  }
  .tt-selected-itens,
  .tt-unselected-itens {
    margin: 16px 0;
  }
  .tt-spinner {
    display: flex;
    justify-content: center;
  }
}
