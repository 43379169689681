.genomic-item {
  flex: 1 1;
  box-shadow: none;
  margin: 0;
  border-radius: 10px;
  padding: 6px;
  height: 100%;
  display: grid;
  grid-template-rows: 33% calc(69% - 16px);
  gap: 16px;
  .gi-header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  .gi-bottom {
    align-self: end;
    overflow: hidden;
  }
  .gi-header {
    .gi-title {
      display: flex;
      justify-content: space-between;
      ion-icon {
        cursor: pointer;
      }
    }
    .gi-subtitle {
      font-size: 10px;
      color: var(--ion-color-medium);
    }
    .gi-subtitle-wrapper {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
    .gi-percentage-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ion-icon {
        cursor: pointer;
      }
    }
  }

  .gi-action {
    font-size: 12px;
    .High {
      color: var(--ion-color-success);
    }
    .Medium {
      color: var(--ion-color-warning);
    }
    .Low {
      color: var(--ion-color-danger);
    }
  }

  .gi-content {
    .gi-content-title {
      font-size: 12px;
      margin-bottom: 5px;
    }
    li {
      font-size: 10px;
    }
  }

  .chart-title {
    font-size: 12px;
    display: block;
    margin-bottom: 8px;
  }

  .barChartItem {
    margin-bottom: 10px;
  }

  ion-button {
    margin: 10px 0;
  }
}
