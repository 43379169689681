.im-spinner {
  text-align: center;
}
.immunotherapy-markers-section {
  background-color: var(--ion-color-light);
  border-radius: 10px;
  padding: 20px;
  .first-line {
    margin-bottom: 15px;
  }
  .innercard {
    background-color: var(--custom-color-white);
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    border-radius: 10px;
  }
  .tm-title {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: baseline;
  }
  .tumor-chips {
    border-radius: 36px;
    border: 1px solid var(--ion-color-medium-tint);
    display: flex;
    overflow: hidden;
    height: 36px;
    align-items: center;
    white-space: nowrap;
    width: fit-content;
    gap: 20px;
  }
  .chip {
    background-color: var(--custom-color-primary-highlight);
    font-weight: bold;
    border-radius: 5px;
    padding: 8px;
    max-width: fit-content;
    .chip-info {
      color: var(--ion-color-medium);
      font-weight: 400;
      display: block;
      font-size: 12px;
    }
  }
  .tc-icon {
    background-color: var(--custom-color-white);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    color: var(--ion-color-secondary);
    font-size: 20px;
    text-align: center;
  }
}
